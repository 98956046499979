import { useState } from "react";
import "./login.css";
import LiveLocation from "./LiveLocation";

import { Button, Card, Form } from "react-bootstrap";
import axios from "axios";

function Login() {
  // const [mobile, setMobile] = useState("");
  // const [password, setPassword] = useState("");

  const [formData,setFormData]=useState(
  {
    mobile : '',
  });

  const [validateResponse,setValidateResponse]=useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Logging in with:", { mobile });
    // Handle authentication logic here

    console.log(formData.mobile);

    const newErrors=validateFormData(formData);
    setErrors(newErrors);

    if(Object.keys(newErrors).length===0)
    {
      console.log("data is valid");
  
      // https://fake-json-api.mock.beeceptor.com/companies

      // fetch("http://bceres.com:9090/api/v1/validate-mobile-number", {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({ mobile: formData.mobile, latitude: "123456", longitude: "123456" })
      // })
      // .then(response => response.json())
      // .then(data => console.log("Success:", data))
      // .catch(error => {
      //   console.error("Fetch Error:", error);
      //   if (error.message.includes("Failed to fetch")) {
      //     console.error("Likely causes: CORS, network issues, or incorrect API URL.");
      //   }
      // });

    

      // const axios = require('axios');
// const FormData = require('form-data');
let data = new FormData();
data.append('mobile', formData.mobile);
data.append('latitude', '28.5710245');
data.append('longitude', '77.3171835');

// let config = {
//   method: 'post',
//   mobile: formData.mobile,
// };

      const headers= {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Headers': '*',
};

      axios.post("https://bceres.com:9090/api/v1/validate-mobile-number", data,headers)
      .then((response) => {
        console.log("response is "+JSON.stringify(response.data));
      })
      .catch(error => {
        console.log("error is "+error);
      });

    }
    else{
      console.log("something is errors");
    }
  };

  const handleChange=(e)=>
  {
    const{name,value}=e.target;
    setFormData({...formData,[name]:value,});  
  }

  const [errors,setErrors]=useState({});

  const validateFormData=(data)=>
  {
    const errors={};
    
    if(!data.mobile.trim())
    {
      errors.mobile="Mobile number is required";
    }
    else if(data.mobile.length<10)
    {
      errors.mobile="Mobile number must be 10 digits in length";
    }

    return errors;
  }

  return (
    <section className="vh-100">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-9 col-lg-6 col-xl-5">
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
              className="img-fluid"
              alt="Sample"
            />
          </div>
          <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">

            <Card>
              <Card.Body>

              <Card.Title> Login </Card.Title>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="mobile">
                <Form.Label>Mobile number*</Form.Label>
                <Form.Control type="tel" name="mobile" maxLength="10" placeholder="Enter mobile number"
                value={formData.mobile}
                onChange={handleChange}
                />
                {errors.mobile&&<p>{errors.mobile}</p>}
              </Form.Group>
              <Button type="submit">Validate</Button>
            <LiveLocation/>
            <p>{validateResponse}</p>
            </Form>
            </Card.Body>
            </Card>

            {/* <form onSubmit={handleSubmit}>
              
            <label className="form-label" htmlFor="form3Example3">
                  Mobile number
                </label>


              <div className="form-outline mb-4">
                <input
                  type="number"
                  id="form3Example3"
                  className="form-control form-control-lg"
                  placeholder="Enter mobile number"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>

              <div className="text-center text-lg-start mt-4 pt-2">
                <button type="submit" className="btn btn-primary btn-lg">
                  Login
                </button>
              
              </div>
            </form> */}
          </div>
        </div>
      </div>

      <div className="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-primary">
        <div className="text-white mb-3 mb-md-0">Smart Outlet.</div>
        <div>
          <a href="#!" className="text-white me-4">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="#!" className="text-white me-4">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="#!" className="text-white me-4">
            <i className="fab fa-google"></i>
          </a>
          <a href="#!" className="text-white">
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>

    </section>
  );
}

export default Login;