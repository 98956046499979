
import {Link } from 'react-router-dom'


function Home()
{
  
    return (
      <div>
  <meta charSet="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
  <meta name="description" content />
  <meta name="author" content />
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
  <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap" rel="stylesheet" />
  <title>Smart Outlet</title>
  {/* Bootstrap core CSS */}
  <link href="vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet" />
  
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossOrigin="anonymous" />
  <link rel="stylesheet" href="assets/css/templatemo-chain-app-dev.css" />
  <link rel="stylesheet" href="assets/css/animated.css" />
  <link rel="stylesheet" href="assets/css/owl.css" />
  {/* <div id="js-preloader" className="js-preloader">
    <div className="preloader-inner">
      <span className="dot" />
      <div className="dots">
        <span />
        <span />
        <span />
      </div>
    </div>
  </div> */}
  <header className="header-area header-sticky wow slideInDown" data-wow-duration="0.75s" data-wow-delay="0s">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <nav className="main-nav">
            <a href="/" className="logo">
              <img src="image.png" width="60px"  height="60px" style={{"margin-left":"20px"}} alt="Smart Outlet" />
            </a>
            <ul className="nav">
              <li className="scroll-to-section"><a href="#top" className="active">Home</a></li>
              <li className="scroll-to-section"><a href="#services">Services</a></li>
              <li className="scroll-to-section"><a href="#about">About</a></li>
              <li className="scroll-to-section"><a href="#pricing">Pricing</a></li>
              <li className="scroll-to-section"><a href="#newsletter">Newsletter</a></li>

              {/* <li><div class="gradient-button"><a id="modal_trigger" href="#modal"><i class="fa fa-sign-in-alt"></i> Sign In Now</a></div></li>  */}


              <li>
                <div className="gradient-button">
                <Link id="modal_trigger" to="/login">
                  <i className="fa fa-sign-in-alt" /> Sign In Now
                </Link>
                </div>
              </li>

            </ul>        
            <a className="menu-trigger">
              <span>Menu</span>
            </a>
          </nav>
        </div>
      </div>
    </div>
  </header>
  <div id="modal" className="popupContainer" style={{"display":"none"}}>
    <div className="popupHeader">
      <span className="header_title">Login</span>
      <span className="modal_close"><i className="fa fa-times" /></span>
    </div>
    <section className="popupBody">
      {/* Social Login */}
      <div className="social_login">
        <div className>
          <a href="#" className="social_box fb">
            <span className="icon"><i className="fab fa-facebook" /></span>
            <span className="icon_title">Connect with Facebook</span>
          </a>
          <a href="#" className="social_box google">
            <span className="icon"><i className="fab fa-google-plus" /></span>
            <span className="icon_title">Connect with Google</span>
          </a>
        </div>
        <div className="centeredText">
          <span>Or use your Email address</span>
        </div>
        <div className="action_btns">
          <div className="one_half"><a href="#" id="login_form" className="btn">Login</a></div>
          <div className="one_half last"><a href="#" id="register_form" className="btn">Sign up</a></div>
        </div>
      </div>
      {/* Username & Password Login form */}
      <div className="user_login">
        <form>
          <label>Email / Username</label>
          <input type="text" />
          <br />
          <label>Password</label>
          <input type="password" />
          <br />
          <div className="checkbox">
            <input id="remember" type="checkbox" />
            <label htmlFor="remember">Remember me on this computer</label>
          </div>
          <div className="action_btns">
            <div className="one_half"><a href="#" className="btn back_btn"><i className="fa fa-angle-double-left" /> Back</a></div>
            <div className="one_half last"><a href="#" className="btn btn_red">Login</a></div>
          </div>
        </form>
        <a href="#" className="forgot_password">Forgot password?</a>
      </div>
      {/* Register Form */}
      <div className="user_register">
        <form>
          <label>Full Name</label>
          <input type="text" />
          <br />
          <label>Email Address</label>
          <input type="email" />
          <br />
          <label>Password</label>
          <input type="password" />
          <br />
          <div className="checkbox">
            <input id="send_updates" type="checkbox" />
            <label htmlFor="send_updates">Send me occasional email updates</label>
          </div>
          <div className="action_btns">
            <div className="one_half"><a href="#" className="btn back_btn"><i className="fa fa-angle-double-left" /> Back</a></div>
            <div className="one_half last"><a href="#" className="btn btn_red">Register</a></div>
          </div>
        </form>
      </div>
    </section>
  </div>
  <div className="main-banner wow fadeIn" id="top" data-wow-duration="1s" data-wow-delay="0.5s">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="left-content show-up header-text wow fadeInLeft" data-wow-duration="1s" data-wow-delay="1s">
                <div className="row">
                  <div className="col-lg-12">
                    <h2>Get The Latest App From App Stores</h2>
                    <p>Smart outlet provides smart way to do the business with your business patrners, We are providing Recharges, Bill payments, Domestic Money transfer, Aadhaar based payments, M-POS, Gift card, CMS etc. Services.</p>
                  </div>
                  <div className="col-lg-12">
                    <div className="white-button scroll-to-section">
                      <a href="https://play.google.com/store/apps/details?id=com.smart.outlet">Get from play store <i className="fab fa-google-play" /></a>
                    </div>
                    <div className="white-button first-button scroll-to-section">
                      <a href>Get From App store <i className="fab fa-apple" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="right-image wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                <img src="assets/images/slider-dec.png" alt="image-slider" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="services" className="services section">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <div className="section-heading  wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
            <h4>Amazing <em>Services &amp; Features</em> for you</h4>
            <img src="assets/images/heading-line-dec.png" alt="header-line-dec" />
            <p>If you are thinking about your business and you want your own software, then <a rel="nofollow" href="https://pay2all.in/contact" target="_blank">contact us</a>. we will develop your own software and help you to start you own business, for more information contact us on given details.</p>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
          <div className="service-item first-service">
            <div className="icon" />
            <h4>App Development</h4>
            <p>We are an Apps development company, we will develop Native android and iOS Apps as per your requiredment</p>
            <div className="text-button">
              <a href="https://pay2all.in/contact">Know More <i className="fa fa-arrow-right" /></a>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="service-item second-service">
            <div className="icon" />
            <h4>Web Development</h4>
            <p>We are an web development company, we are developing Fintech, e-commerce, CRM etc. types of websites</p>
            <div className="text-button">
              <a href="https://pay2all.in/contact">Know More <i className="fa fa-arrow-right" /></a>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="service-item third-service">
            <div className="icon" />
            <h4>API Provider</h4>
            <p>We are also providing api as per clients requiredment, we are providing Recharge, Bill payments, DMT, Payout, AEPS etc APIs.</p>
            <div className="text-button">
              <a href="https://pay2all.in/contact">Know More <i className="fa fa-arrow-right" /></a>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="service-item fourth-service">
            <div className="icon" />
            <h4>24/7 Help &amp; Support</h4>
            <p>If you will have any issues regarding your software, we are here to help you 24/7, you can contact us on give contact details</p>
            <div className="text-button">
              <a href="https://pay2all.in/contact">Contact us <i className="fa fa-arrow-right" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="about" className="about-us section">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 align-self-center">
          <div className="section-heading">
            <h4>About <em>What We Do</em> &amp; Who We Are</h4>
            <img src="assets/images/heading-line-dec.png" alt ="header-line-dec"/>
            <p>We specialize in building custom software, websites, and mobile apps tailored to business needs. From user-friendly interfaces to robust backend systems, we deliver reliable and scalable solutions to help businesses thrive in a digital world.</p>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="box-item">
                <h4><a href="#">Develop &amp; Maintance</a></h4>
                <p>We are develop and maintain softwares</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="box-item">
                <h4><a href="#">24/7 Support &amp; Help</a></h4>
                <p>We are providing support 24 x 7</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="box-item">
                <h4><a href="#">Fixing Issues About</a></h4>
                <p>Software</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="box-item">
                <h4><a href="#">API provider</a></h4>
                <p>We are providing Fintech API</p>
              </div>
            </div>
            <div className="col-lg-12">
              <p>We are a team of skilled software developers focused on creating innovative and efficient technology solutions. We work together to deliver high-quality products that meet the needs of our clients and users.</p>
              <div className="gradient-button">
                <a href="https://pay2all.in/contact">Get Demo</a>
              </div>
              <span>*It's absolutely free, so why aren't you getting a demo?</span>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="right-image">
            <img src="assets/images/about-right-dec.png" alt="about-right-dec" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="clients" className="the-clients">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <div className="section-heading">
            <h4>Check What <em>The Clients Say</em> About Our App Dev</h4>
            <img src="assets/images/heading-line-dec.png" alt />
            <p>Our clients consistently praise our app development for its reliability, user-friendly design, and seamless performance. They appreciate our commitment to understanding their unique needs and delivering custom solutions that exceed expectations. Many highlight our ability to innovate, meet deadlines, and provide ongoing support, ensuring their apps remain top-notch.</p>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="naccs">
            <div className="grid">
              <div className="row">
                <div className="col-lg-7 align-self-center">
                  <div className="menu">
                    <div className="first-thumb active">
                      <div className="thumb">
                        <div className="row">
                          <div className="col-lg-4 col-sm-4 col-12">
                            <h4>Dinesh Kumar</h4>
                            <span className="date">04 Feb 2025</span>
                          </div>
                          <div className="col-lg-4 col-sm-4 d-none d-sm-block">
                            <span className="category">Digital Business</span>
                          </div>
                          <div className="col-lg-4 col-sm-4 col-12">
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <span className="rating">5.0</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="thumb">
                        <div className="row">
                          <div className="col-lg-4 col-sm-4 col-12">
                            <h4>Ajam shaikh</h4>
                            <span className="date">18 May 2020</span>
                          </div>
                          <div className="col-lg-4 col-sm-4 d-none d-sm-block">
                            <span className="category">Digital Business</span>
                          </div>
                          <div className="col-lg-4 col-sm-4 col-12">
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <span className="rating">5.0</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="thumb">
                        <div className="row">
                          <div className="col-lg-4 col-sm-4 col-12">
                            <h4>SUJEET KUMAR KUSHWAHA</h4>
                            <span className="date">1 May 2020</span>
                          </div>
                          <div className="col-lg-4 col-sm-4 d-none d-sm-block">
                            <span className="category">Digital Business</span>
                          </div>
                          <div className="col-lg-4 col-sm-4 col-12">
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <span className="rating">5.0</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="thumb">
                        <div className="row">
                          <div className="col-lg-4 col-sm-4 col-12">
                            <h4>Royal King Naresh</h4>
                            <span className="date">14 June 2020</span>
                          </div>
                          <div className="col-lg-4 col-sm-4 d-none d-sm-block">
                            <span className="category">Digital Business</span>
                          </div>
                          <div className="col-lg-4 col-sm-4 col-12">
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <span className="rating">5.0</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="last-thumb">
                      <div className="thumb">
                        <div className="row">
                          <div className="col-lg-4 col-sm-4 col-12">
                            <h4>Ritika Singh</h4>
                            <span className="date">5 February 2025</span>
                          </div>
                          <div className="col-lg-4 col-sm-4 d-none d-sm-block">
                            <span className="category">Digital Business</span>
                          </div>
                          <div className="col-lg-4 col-sm-4 col-12">
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <span className="rating">5.0</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
                <div className="col-lg-5">
                  <ul className="nacc">
                    <li className="active">
                      <div>
                        <div className="thumb">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="client-content">
                                <img src="assets/images/quote.png" alt ="quote"/>
                                <p>“Superb app for aeps payment services”</p>
                              </div>
                              <div className="down-content">
                                <img src="assets/images/client-image.jpg" alt ="client-image"/>
                                <div className="right-content">
                                  <h4>Dinesh Kumar</h4>
                                  <span>Owner &amp; director of Rajcom payments and software services</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div>
                        <div className="thumb">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="client-content">
                                <img src="assets/images/quote.png" alt ="quote"/>
                                <p>“It is better than all aeps service good job.”</p>
                              </div>
                              <div className="down-content">
                                <img src="assets/images/client-image.jpg" alt="client-image" />
                                <div className="right-content">
                                  <h4>ajam shaikh</h4>
                                  <span>BTrack user</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div>
                        <div className="thumb">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="client-content">
                                <img src="assets/images/quote.png" alt="quote" />
                                <p>“Best Aeps service in this App ..Very good i am so happy .Server is most strong.”</p>
                              </div>
                              <div className="down-content">
                                <img src="assets/images/client-image.jpg" alt="client-image" />
                                <div className="right-content">
                                  <h4>SUJEET KUMAR KUSHWAHA</h4>
                                  <span>CSC User</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div>
                        <div className="thumb">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="client-content">
                                <img src="assets/images/quote.png" alt="quote" />
                                <p>“Best experience ever 😊”</p>
                              </div>
                              <div className="down-content">
                                <img src="assets/images/client-image.jpg" alt="client-image" />
                                <div className="right-content">
                                  <h4>Royal King Naresh</h4>
                                  <span>Smart Outlet user</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div>
                        <div className="thumb">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="client-content">
                                <img src="assets/images/quote.png" alt="quote" />
                                <p>“Bhut achha services h Fast kaam hota hai.”</p>
                              </div>
                              <div className="down-content">
                                <img src="assets/images/client-image.jpg" alt="client-image" />
                                <div className="right-content">
                                  <h4>Ritika Singh</h4>
                                  <span>Smart outlet user</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>          
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="pricing" className="pricing-tables">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <div className="section-heading">
            <h4>We Have The Best Pre-Order <em>Prices</em> You Can Get</h4>
            <img src="assets/images/heading-line-dec.png" alt="quote" />
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eismod tempor incididunt ut labore et dolore magna.</p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="pricing-item-regular">
            <span className="price">$12</span>
            <h4>Standard Plan App</h4>
            <div className="icon">
              <img src="assets/images/pricing-table-01.png" alt="client-image" />
            </div>
            <ul>
              <li>Lorem Ipsum Dolores</li>
              <li>20 TB of Storage</li>
              <li className="non-function">Life-time Support</li>
              <li className="non-function">Premium Add-Ons</li>
              <li className="non-function">Fastest Network</li>
              <li className="non-function">More Options</li>
            </ul>
            <div className="border-button">
              <a href="#">Purchase This Plan Now</a>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="pricing-item-pro">
            <span className="price">$25</span>
            <h4>Business Plan App</h4>
            <div className="icon">
              <img src="assets/images/pricing-table-01.png" alt="pricing" />
            </div>
            <ul>
              <li>Lorem Ipsum Dolores</li>
              <li>50 TB of Storage</li>
              <li>Life-time Support</li>
              <li>Premium Add-Ons</li>
              <li className="non-function">Fastest Network</li>
              <li className="non-function">More Options</li>
            </ul>
            <div className="border-button">
              <a href="#">Purchase This Plan Now</a>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="pricing-item-regular">
            <span className="price">$66</span>
            <h4>Premium Plan App</h4>
            <div className="icon">
              <img src="assets/images/pricing-table-01.png" alt="pricing-table" />
            </div>
            <ul>
              <li>Lorem Ipsum Dolores</li>
              <li>120 TB of Storage</li>
              <li>Life-time Support</li>
              <li>Premium Add-Ons</li>
              <li>Fastest Network</li>
              <li>More Options</li>
            </ul>
            <div className="border-button">
              <a href="#">Purchase This Plan Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> 
  <footer id="newsletter">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <div className="section-heading">
            <h4>Join our mailing list to receive the news &amp; latest trends</h4>
          </div>
        </div>
        <div className="col-lg-6 offset-lg-3">
          <form id="search" action="#" method="GET">
            <div className="row">
              <div className="col-lg-6 col-sm-6">
                <fieldset>
                  <input type="address" name="address" className="email" placeholder="Email Address..." autoComplete="on" required />
                </fieldset>
              </div>
              <div className="col-lg-6 col-sm-6">
                <fieldset>
                  <button type="submit" className="main-button">Subscribe Now <i className="fa fa-angle-right" /></button>
                </fieldset>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3">
          <div className="footer-widget">
            <h4>Contact Us</h4>
            <p>24B, K.S. Corporate tower, Film city, sector 16A, Noida, Uttar Pradesh, India 201301</p>
            <p><a href="tel:+918448449290">+91 844 844 9290</a></p>
            <p><a href="mailto:info@pay2all.in">info@pay2all.in</a></p>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="footer-widget">
            <h4>About Us</h4>
            <ul>
              <li><a href="#">Home</a></li>
              <li><a href="#services">Services</a></li>
              <li><a href="#about">About</a></li>
              <li><a href="#">Testimonials</a></li>
              <li><a href="#pricing">Pricing</a></li>
            </ul>
            <ul>
              <li><a href="#">About</a></li>
              <li><a href="#">Testimonials</a></li>
              <li><a href="#">Pricing</a></li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="footer-widget">
            <h4>Useful Links</h4>
            <ul>
              <li><a href="#">Free Apps</a></li>
              <li><a href="#">App Engine</a></li>
              <li><a href="#">Programming</a></li>
              <li><a href="#">Development</a></li>
              <li><a href="#">App News</a></li>
            </ul>
            <ul>
              <li><a href="#">App Dev Team</a></li>
              <li><a href="#">Digital Web</a></li>
              <li><a href="#">Normal Apps</a></li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="footer-widget">
            <h4>About Our Company</h4>
            <div className="logo">
              <img src="image.png" alt="Smart outlet logo" />
            </div>
            <p>We are a team of skilled software developers focused on creating innovative and efficient technology solutions. We work together to deliver high-quality products that meet the needs of our clients and users.</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
  {/* Scripts */}
</div>
    );
}

export default Home;